@import 'Styles/includes';

/**
*
* PeopleSearchResult
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.PeopleSearchResult {
    &__Result {
        margin-top: 30px;
    }

    &__ResultCount {
        font-size: 1.3rem;

        strong {
            font-weight: 600;
        }
    }

    &__Result {
        margin-bottom: 24px;

        @include media(md) {
            margin-bottom: 36px;
            margin-left: -16px;
            display: flex;
            flex-wrap: wrap;
        }
    }

    &__Hit {
        margin-bottom: 16px;

        @include media(md) {
            width: calc((100% / 3) - 16px);
            margin: 0 0 16px 16px;
        }
    }
}
