@import 'Styles/includes';

/**
*
* ArrangementSearchResult
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ArrangementSearchResult {
    padding: 15px;
    position: relative;

    &__Row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include media(lg) {
            margin-top: 10px;
            padding: 5px 0 23px 0;
            border-bottom: 1px solid $gray-10;
        }
    }

    &__OnlyDistanceArrangementsFound {
        position: relative;
        border-radius: 4px;
        padding: 8px 16px 8px 48px;
        line-height: 1.5;
        margin-bottom: 16px;
        font-size: 1.4rem;
        border: 1px solid $gray-40;
        border-radius: 4px;
        background: #fbfbfb;
        color: $black;

        @include media(md) {
            font-size: 1.6rem;
        }

        &:before {
            content: 'i';
            border-radius: 9px;
            font-size: 1.4rem;
            width: 18px;
            text-align: center;
            height: 19px;
            font-family: Georgia, serif;
            font-style: italic;
            font-weight: bold;
            line-height: 19px;
            position: absolute;
            left: 16px;
            top: calc(50% - 10px);
            color: white;
            background-color: $red;
        }
    }

    &__ResultInfo {
        font-size: 1.3rem;
        line-height: 1.25;
    }

    &__OccasionsContainer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__OccasionWrapper {
        width: 100%;
        margin: 12px 0;

        @include media(sm) {
            width: calc(50% - 12px);
        }

    }

    &__HitsCount {
        font-weight: 700;
    }

    &__AjaxLoader {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 15px;
    }

    &__SortingsSelect {
        color: $text;
        border: none;
        position: relative;
        background: transparent;
        font-size: 1.3rem;
        appearance: none;
        outline: none;
        text-align-last: right;
        padding: 0 20px 0 15px;
        background-repeat: no-repeat;
        background-position: right 0 center;
        background-image: url('#{$publicpath}img/icon-arrow-down.svg');
        background-size: 17px;
    }

    &__LoadMoreContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
    }

    &__EmptyResult {
        text-align: center;
        margin-top: 36px;
        margin-bottom: 36px;
    }

    &__EmptyResultIcon {
        font-size: 8rem;
        margin-bottom: 16px;
    }

    &__EmptyResultTitle {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 16px;
    }
}
