@import 'Styles/includes';

/**
*
* ArrangementSearchFilters
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ArrangementSearchFilters {
    $root: &;
    padding: 22px 0;
    width: 100%;

    &--Overlay {
        padding: 5px 0;
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        background: white;
        z-index: 100;
        box-sizing: border-box;
    }

    &__FilterSection {
        margin-top: 24px;
    }

    &__TitleWrap {
        display: flex;
        justify-content: space-between;
        padding: 0 12px;
    }

    &__Title {
        font-size: 2.2rem;
        font-weight: 700;

        #{$root}--Desktop & {
            font-size: 1.6rem;
        }
    }

    &__ResetFilter {
        display: flex;
        cursor: pointer;
        align-items: center;
        background: none;
        border: none;
        padding: 0;
        outline: 0;

        &:focus {
            border: none;
        }
    }

    &__ResetFilterIcon {
        position: relative;
        display: inline-block;
        width: 16px;
        height: 16px;
        overflow: hidden;
        margin-right: 5px;

        &::before,
        &::after {
            content: '';
            position: absolute;
            height: 2px;
            width: 100%;
            top: 50%;
            left: 0;
            margin-top: -1px;
            background: $button-bg;
            border-radius: 1px;
        }

        &::before {
            transform: rotate(45deg);
        }

        &::after {
            transform: rotate(-45deg);
        }
    }

    &__ResetFilterText {
        color: #373737;
        font-size: 1.4rem;
    }

    &__StickyWrapper {
        height: 58px;
        z-index: 1;

        @include media(lg) {
            height: 47px;
        }
    }

    &__FilterLinkWrapper {
        width: 190px;
        margin: auto;
        padding: 15px 5px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        box-shadow: 0 2px 6px 0 rgba(black, 0.2);
        border-radius: 100px;

        &--Sticky {
            position: fixed;
            top: 10px;
            left: 0;
            right: 0;
            background-color: white;
            z-index: 1;
        }

        #{$root}--Overlay & {
            width: 100%;
            box-shadow: none;
            border-radius: 0;
            justify-content: left;
            padding: 20px 0;
            border-bottom: 1px solid $gray-10;
            @include u-wrap(site);
        }
    }

    &__Lines {
        display: flex;
        flex-direction: column;
        align-items: center;
        flex-wrap: wrap;
        height: 17px;
        width: 25px;
        margin-right: 5px;
    }

    &__Line {
        margin-top: 2px;
        background: $red;
        border-radius: 2px;
        height: 2px;

        &--Upper {
            width: 13px;
        }

        &--Middle {
            width: 9px;
        }

        &--Bottom {
            width: 5px;
        }
    }

    &__FilterCount {
        position: absolute;
        top: -5px;
        right: -5px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $red;
        border-radius: 50%;
        width: 20px;
        height: 20px;

        #{$root}--Overlay & {
            display: none;
        }
    }

    &__FilterCountText {
        color: white;
        font-size: 1rem;
    }

    &__FilterLink {
        font-size: 1.4rem;
        opacity: 0.6;
        color: $black;
        text-decoration: none;

        #{$root}--Overlay & {
            opacity: 1;
        }
    }

    &__CloseOverlay {
        display: none;

        #{$root}--Overlay & {
            display: block;
            width: 13px;
            position: absolute;
            height: 13px;
            top: 20px;
            right: 25px;
            background-size: cover;
            background-repeat: no-repeat;
            background-image: url('#{$publicpath}img/close.svg');
            cursor: pointer;
        }
    }

    &__ToggleContainer {
        position: relative;
        display: none;
        height: calc(100vh - 130px);
        overflow-y: scroll;
        padding-bottom: 20px;

        @supports (-webkit-touch-callout: none) {
            /* CSS specific to iOS devices */ 
            padding-bottom: 75px;
        }

        #{$root}--Overlay & {
            display: block;
        }

        #{$root}--Desktop & {
            @include media(lg) {
                height: 100%;
                overflow-y: auto;
                display: block;
            }
        }
    }

    &__ShowResult {
        @include u-wrap(site);
        z-index: 200;
        background: white;
        position: fixed;
        bottom: 0;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        box-shadow: 0 2px 14px 0 rgba(black, 0.5);
        max-width: none;

        @include media(lg) {
            max-width: 100%;
        }
    }

    &__Filter {
        margin-top: 30px;

        #{$root}--Desktop & {
            padding: 0;
            margin-top: 14px;
            margin-right: 15px;
        }
    }

    &__CheckboxListsWrapper {
        margin-top: 31px;

        #{$root}--Desktop & {
            margin-top: 18px;
        }
    }

    &__FirstLevel {
        &--Expanded {
            background-color: #fbfbfb;
            @include media(lg) {
                background-color: transparent;
            }
        }
    }

    &__SecondLevel {
        overflow: hidden;
        transition: max-height 0.35s ease;

        #{$root}__CheckboxWrapper {
            padding: 0 0 0 15px;
        }
    }

    &__CheckboxWrapper {
        border-top: 1px solid #e1e1e1;
    }

    &__CheckboxLabel {
        padding: 15px 12px;
        margin-left: 10px;
        font-size: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;

        #{$root}--Desktop & {
            font-size: 1.4rem;
            padding: 10px 0;
        }
    }

    &__Checkbox {
        display: none;
        appearance: none;

        &:checked {
            & ~ #{$root}__Checkmark {
                background-color: $red;

                &:after {
                    content: '';
                    position: absolute;
                    width: 17px;
                    height: 17px;
                    top: 2px;
                    left: 2px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    background-image: url('#{$publicpath}img/icon-checkmark.svg');
                }
            }
        }
    }

    &__CheckboxText {
        display: flex;
        align-items: center;
    }

    &__CheckboxLabelToggle {
        display: block;
        background-image: url('#{$publicpath}img/icon-arrow-down.svg');
        background-repeat: no-repeat;
        background-size: cover;
        width: 15px;
        height: 10px;
        margin-left: 4px;
        transition: transform 0.25s ease;
        transform: rotate(-180deg);

        &--Expanded {
            transform: rotate(0);
        }
    }

    &__Checkmark {
        position: relative;
        height: 22px;
        width: 22px;
        margin-right: 15px;
        background-color: #e1e1e1;
        border-radius: 4px;
        padding-left: 20px;
    }
}
