@import 'Styles/includes';

.CardMethodListViewport {
    display: flex;
    flex-direction: column;
    color: $text;

    &__Title {
        font-size: 3.6rem;
        font-weight: bold;
        line-height: 1.33;
        letter-spacing: 0.16px;
        margin-bottom: 86px;
    }

    &__List {
        display: flex;
        flex-flow: row wrap;
        margin-left: -25px;
    }

    &__Item {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        width: calc(100% - 25px);
        margin: 0 0 16px 25px;

        @include media(sm) {
            width: calc(100% / 2 - 25px);
            margin-bottom: 25px;
        }

        @include media(lg) {
            width: calc(100% / 3 - 25px);
            margin-bottom: 48px;
        }
    }

    &__Empty {
        text-align: center;
        margin-top: 36px;
        margin-bottom: 36px;
    }

    &__EmptyIcon {
        font-size: 8rem;
        margin-bottom: 16px;
    }

    &__EmptyTitle {
        font-size: 1.8rem;
        font-weight: 600;
        line-height: 1.5;
        margin-bottom: 16px;
    }
}
