@import 'Styles/includes';

/**
*
* CardResource
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardResource {
    $root: &;

    position: relative;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    height: 100%;
    color: $text;
    border-radius: 4px;

    // &:focus {
    //     outline: 2px solid $black;
    // }

    // Adds background color and image for selected icon
    @include agenda-2030-modifiers();

    &--ImageAsBackground,
    &--Agenda2030,
    &--BgHex,
    &--WhiteText {
        color: white;
    }

    &--BlackText {
        color: $text !important;
    }

    // Adds background color, must be placed after above
    @include sensus-bg-modifiers();

    &--NoImage {
        justify-content: center;
    }

    &__Image {
        position: relative;
        height: 120px;
        width: 100%;
        border-radius: 4px 4px 0 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        #{$root}--ImageAsBackground & {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 4px;

            &::after {
                display: block;
            }
        }

        &::after {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(black, .4);
        }
    }

    &__Icon {
        position: relative;
        margin: 24px;
        max-width: 100%;
        width: 200px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        @include agenda-2030-modifiers(true);

        img {
            width: auto;
            height: auto;
            max-width: 100%;
        }

        #{$root}--Agenda2030 & {
            height: 200px;
        }

        #{$root}--Agenda2030:not(#{$root}--BgHex):not(#{$root}--ImageAsBackground) & {
            margin: 16px 0 0 4px;
        }
    }


    &__Content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
        color: inherit;
        padding: 16px;

        #{$root}--Icon & {
            padding-top: 0;
        }

        @include media(md) {
            padding: 24px;
        }
    }

    &__Label {
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-bottom: 8px;
        letter-spacing: .5px;

        @include media(md) {
            font-size: 1.4rem;
        }
    }

    &__Title {
        font-size: 2.2rem;
        font-weight: bold;
        line-height: 1.33;
        margin-bottom: 8px;
    }

    &__Categories {
        margin-top: 24px;
    }

    &__Fas {
        font-size: 1.4rem;
        width: 24px;
        color: inherit;
    }

    &__Category {
        align-items: center;
        display: flex;
        font-size: 1.1rem;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-bottom: 8px;

        &--More {
            padding-left: 0;
            background: none;
        }
    }

    &__Text {
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: 0.4px;
        padding-right: 8px;
        overflow: hidden;

        &::after {
            content: url('#{$publicpath}img/icon-arrow-right.svg');
            transition: left 0.25s ease;
            display: inline-block;
            position: relative;
            top: 2px;
            left: 8px;
            width: 15px;

            #{$root}--ImageAsBackground &,
            #{$root}--Agenda2030 &,
            #{$root}--BgHex &,
            #{$root}--WhiteText & {
                content: url('#{$publicpath}img/icon-arrow-right--white.svg');
            }

            #{$root}--BlackText & {
                content: url('#{$publicpath}img/icon-arrow-right.svg') !important;
            }
        }

        #{$root}:hover & {
            &::after {
                left: 12px;
            }
        }
    }
}
