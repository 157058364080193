@import 'Styles/includes';

/**
*
* SearchHit
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.SearchHit {
    $root: &;

    display: flex;
    flex-direction: column;
    text-decoration: none;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(black, 0.15);
    background-color: white;
    transition: box-shadow 0.15s ease;
    overflow: hidden;
    justify-content: flex-end;

    &--HasImage {
        justify-content: flex-start;
        position: relative;
    }

    &__Type {
        color: $text;
        background-color: white;
        text-transform: uppercase;
        letter-spacing: 0.33px;
        font-weight: 600;
        font-size: 1.2rem;
        border-radius: 2px;
        line-height: 1.25;
        display: block;
        margin-bottom: 8px;

        #{$root}--HasImage & {
            position: absolute;
            box-shadow: 0 0 4px 0 rgba(black, 0.15);
            padding: 2px 4px;
            top: 16px;
            left: 16px;

            @include media(md) {
                top: 16px;
                left: 16px;
            }
        }
    }

    &__Image {
        width: 100%;
        height: 120px;
        background-repeat: no-repeat;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: $gray-10;

        @include media(md) {
            height: 150px;
        }

        #{$root}--Contact & {
            height: 120px;
            width: 120px;
            margin: 24px auto 0px auto;
            border-radius: 50%;

            @include media(md) {
                margin: 36px auto 0 auto;
            }
        }
        
        &--OfficeFallback {
            //border-radius: 0 !important;
            //background-color: transparent;
            background-size: 50px;
            background-image: url('#{$publicpath}img/icon-office.svg');
        }
    }

    &:hover {
        box-shadow: 0 0 6px 0 rgba(black, 0.3);

        @media (hover: none) {
            box-shadow: 0 0 4px 0 rgba(black, 0.15);
        }
    }

    @include media(md) {
        height: 100%;
    }

    &__Content {
        padding: 16px 16px 16px 16px;
    }

    &__Breadcrumbs {
        display: block;
        margin-bottom: 8px;
    }

    &__Breadcrumb {
        text-decoration: none;
        letter-spacing: 0.33px;
        font-size: 1.2rem;
        color: rgba($text, 0.75);
        position: relative;

        &:after {
            content: '/';
            margin: 0 8px;
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }
    }

    &__Title {
        font-size: 1.8rem;
        font-weight: bold;
        line-height: 1.33;
        color: $text;
        margin-bottom: 8px;
        display: inline-block;
        position: relative;

        @include media(md) {
            font-size: 2rem;
        }
        
        // #{$root}:hover & {
        //     text-underline-offset: 2px;
        //     text-decoration-thickness: 1px;
        //     text-decoration: underline;
        // }
    }

    &__Preamble {
        font-size: 1.6rem;
        line-height: 1.35;
        margin-bottom: 8px;
        color: $text;
    }

    &__MetaList {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0 16px 16px 16px;
    }

    &__Distance {
        width: fit-content;
        margin: -7px 16px 12px 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.2rem;
        letter-spacing: 0.9px;
        display: inline-flex;
        align-items: center;
        color: #961313;
        padding: 4px 8px;
        border-radius: 4px;
        background: rgba(175, 20, 50, 0.23);
    }

    &__MetaListItem {
        display: block;
        margin-bottom: 4px;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    &__MetaListIcon {
        margin-right: 8px;
        width: 14px;
    }

    &__MetaListCategory {
        color: $black;
        font-size: 1.1rem;
        margin-bottom: 8px;
        position: relative;
        display: block;
        text-transform: uppercase;
        flex-shrink: 0;
        letter-spacing: 0.8px;
        margin-left: 0;
    }

    &__MetaItem {
        color: $black;
        font-size: 1.1rem;
        margin-bottom: 12px;
        position: relative;
        text-transform: uppercase;
        flex-shrink: 0;
        letter-spacing: 0.8px;
        margin-left: 0;

        &--List {
            display: block;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &::before {
            content: '';
            background-position: left center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 15px;
            height: 15px;
            position: absolute;
            top: 0;
            left: 0px;
            padding: inherit;
        }

        &--Location {
            padding-left: 26px;

            &::before {
                width: 12px;
                height: 12px;
                background-image: url('#{$publicpath}img/icon-location-black.svg');
                left: 4px;
                top: -2px;
            }
        }

        &--Title {
            font-weight: 600;
            &:before {
                content: none;
            }
        }

        &--Phone {
            padding-left: 26px;

            &::before {
                width: 12px;
                height: 12px;
                background-image: url('#{$publicpath}img/icon-phone.svg');
                left: 3px;
                top: -2px;
            }
        }

        &--Email {
            padding-left: 26px;

            &::before {
                width: 12px;
                height: 12px;
                background-image: url('#{$publicpath}img/icon-email.svg');
                left: 3px;
                top: -2px;
            }
        }

        &--Price {
            padding-left: 26px;

            &::before {
                top: -2px;
                left: 0;
                width: 17px;
                height: 13px;
                background-image: url('#{$publicpath}img/icon-card.svg');
            }
        }

        &--Date {
            padding-left: 26px;

            &::before {
                top: -4px;
                left: 0;
                width: 17px;
                height: 17px;
                background-image: url('#{$publicpath}img/icon-clock.svg');
            }
        }

        &--GroupSize {
            padding-left: 26px;

            &::before {
                top: -4px;
                left: 0;
                width: 17px;
                height: 17px;
                background-image: url('#{$publicpath}img/icon-accessibility.svg');
            }
        }

        &--Category {
            
        }
    }
}
