@import 'Styles/includes';

/**
*
* HeroArticle
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.HeroArticle {
    $root: &;

    background-color: rgba($gray-10, 0.5);

    // Adds background color and image for selected icon
    @include agenda-2030-modifiers();

    &--Agenda2030,
    &--BgHex,
    &--WhiteText {
        color: white;
    }

    &--BlackText {
        color: $text !important;
    }

    // Adds background color, must be placed after above
    @include sensus-bg-modifiers();

    &__ImageContainer {
        margin-bottom: 24px;

        @include media(md) {
            margin-bottom: 54px;
        }
    }

    &__Image {
        background-color: $gray-10;
        height: 280px;
        width: 100vw;
        position: relative;
        left: 50%;
        transform: translateX(-50%);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        #{$root}--Agenda2030 &,
        #{$root}--BgHex & {
            background-color: transparent;
        }

        @include media(md) {
            width: 100%;
            position: initial;
            transform: none;
            height: 540px;

            #{$root}--FullWidthImage & {
                position: relative;
                left: 50%;
                width: 100vw;
                transform: translateX(-50%);
            }
        }
    }

    &__Photographer {
        position: relative;
        width: 100vw;
        left: 50%;
        transform: translateX(-50%);
        background-color: $gray-10;
        padding: 16px;
        opacity: 0.75;
        color: $text;
        font-size: 1.2rem;
        font-weight: 600;
        text-transform: uppercase;
        letter-spacing: 1px;

        @include media(md) {
            width: auto;
            margin-bottom: 54px;
        }

        &::before {
            content: '';
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: $gray-10;
            z-index: -1;

            @include media(md) {
                width: 100vw;
                left: 50%;
                transform: translateX(-50%);
            }

            #{$root}--FullWidthImage & {
                display: block;
            }
        }

        #{$root}--Agenda2030#{$root}--FullWidthImage &,
        #{$root}--BgHex#{$root}--FullWidthImage & {
            background-color: transparent;
        }

    }

    &__Container {
        @include u-wrap(site);
        overflow: hidden;

        #{$root}--FullWidthImage & {
            overflow: visible;
        }

        padding-top: 24px;
        padding-bottom: 24px;

        #{$root}--HasImage & {
            padding-top: 0;
        }

        @include media(md) {
            padding-top: 80px;
            padding-bottom: 48px;

            #{$root}--FullWidthImage & {
                padding-top: 0;
            }
        }
    }

    &__Layout {
        display: flex;
        flex-direction: column;

        @include media(sm) {
            flex-direction: row;
        }
    }

    &__Icon {
        flex: 0 0 auto;
        max-width: 100%;
        width: 200px;
        margin-bottom: 24px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;

        @include agenda-2030-modifiers(true);

        img {
            width: auto;
            height: auto;
            max-width: 100%;
        }

        @include media(md) {
            width: 300px;
            margin-right: 24px;
            margin-bottom: 0;
        }

        #{$root}--Agenda2030 & {
            height: 200px;

            @include media(md) {
                height: 300px;
            }
        }

        #{$root}--Agenda2030:not(#{$root}--BgHex) & {
            margin-bottom: 0;
        }
    }

    &__Content {
        flex: 1 1 auto;

        @include media(md) {
            max-width: 756px;
            margin: 0 auto;
        }
    }

    &__Label {
        font-size: 1.2rem;
        text-transform: uppercase;
        margin-bottom: 8px;
        letter-spacing: .5px;

        @include media(md) {
            font-size: 1.4rem;
        }
    }

    &__Title {
        font-size: 2.4rem;
        line-height: 1.2;
        margin-bottom: 12px;
        font-weight: bold;

        @include media(md) {
            font-size: 3.6rem;
        }
    }

    &__MetaContiner {
        margin-bottom: 24px;
    }

    &__Date {
        text-transform: uppercase;
        font-size: 1.1rem;
        letter-spacing: 0.8px;
        display: block;
        margin-bottom: 8px;
    }

    &__Tags {
        display: flex;
        flex-wrap: wrap;
    }

    &__Tag {
        text-transform: uppercase;
        font-size: 1.1rem;
        letter-spacing: 0.8px;
        padding: 4px 6px;
        margin-right: 4px;
        margin-bottom: 4px;
        border: 1px solid black;
        border-radius: 2px;
    }

    &__Preamble {
        font-size: 1.6rem;
        color: inherit;
        letter-spacing: 0.4px;
        line-height: 1.66;

        @include media(md) {
            font-size: 1.8rem;
        }
    }

    &__Button {
        margin-top: 16px;

        @include media(md) {
            margin-top: 32px;
        }
    }
}
