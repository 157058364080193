@import 'Styles/includes';

.CardMethodList {
    $root: &;

    color: $text;

    &__Header {
        padding-bottom: 16px;
        margin-bottom: 24px;
        text-align: center;
        border-bottom: 1px solid $gray-20;

        @include media(md) {
            max-width: 75%;
            padding-bottom: 32px;
            margin: 0 auto 48px auto;
        }
    }

    &__Tag {
        font-size: 1.6rem;
        font-weight: 700;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 5px;
        line-height: 1.5;
        margin-bottom: 8px;
        letter-spacing: 0.4px;

        @include media(lg) {
            font-size: 1.8rem;
            margin-bottom: 8px;
        }
    }

    &__Title {
        font-weight: bold;
        line-height: 1.33;
        font-size: 2.4rem;

        @include media(lg) {
            font-size: 3.6rem;
        }
    }

    &__Filter {
        margin-bottom: 18px;

        #{$root}--Full & {
            background: $gray-15;
        }

        @include media(sm) {
            margin-bottom: 40px;
        }

        @include media(md) {
            margin-bottom: 65px;
        }
    }

    &__FilterPanel {
        #{$root}--Full & {
            @include u-wrap(article);

            padding-top: 24px;
            padding-bottom: 24px;

            @include media(md) {
                padding-top: 48px;
                padding-bottom: 48px;
            }
        }
    }

    &__Sections {
        display: flex;
        flex-direction: column;
        padding-bottom: 16px;
        border-bottom: 1px solid #dfdfdf;

        @include media(md) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__Section {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;

        &:first-of-type {
            @include media(md) {
                max-width: calc((169px * 2) - 8px);
            }

            @include media(xl) {
                max-width: calc((169px * 3) - 8px);
            }
        }

        &:not(:first-of-type) {
            @include media(md) {
                width: calc((128px * 2) - 8px);
                margin-left: 24px;
            }
        }
    }

    &__FilterDescription {
        font-size: 1.1rem;
        font-weight: normal;
        letter-spacing: 0.8px;
        text-transform: uppercase;
        margin-top: 8px;
        margin-bottom: 8px;

        @include media(md) {
            margin-top: 0;
            margin-bottom: 16px;
            font-size: 1.6rem;
            letter-spacing: 0.4px;
        }

        &::before {
            content: '';
            display: inline-block;
            position: relative;
            top: 3px;
            width: 18px;
            height: 18px;
            margin-right: 8px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        #{$root}__FilterContainer--Category &::before {
            background-image: url('#{$publicpath}img/icon-category.svg');
        }

        #{$root}__FilterContainer--Time &::before {
            background-image: url('#{$publicpath}img/icon-clock.svg');
        }

        #{$root}__FilterContainer--Group &::before {
            width: 24px;
            background-image: url('#{$publicpath}img/group.svg');
        }
    }

    &__FilterContainer {
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
    }

    &__Buttons {
        flex: 0 0 auto;
        display: flex;
        flex-wrap: wrap;
        margin-left: -8px;
        justify-content: flex-start;
    }

    &__FilterButton {
        cursor: pointer;
        outline: none;
        appearance: none;
        display: inline-block;
        color: $text;
        font-family: inherit;
        font-size: 1.4rem;
        height: 42px;
        min-width: 80px;
        font-weight: bold;
        line-height: 1.71;
        letter-spacing: .28px;
        padding: 0 16px;
        overflow: hidden;
        border: none;
        border-radius: 4px;
        box-shadow: 0 0 4px 0 rgba(black, 0.25);
        background-color: white;
        margin: 0 0 8px 8px;
        transition: box-shadow 0.15s ease;

        &:hover {
            box-shadow: 0 0 4px 0 rgba(black, 0.35);

            @media (hover: none) {
                box-shadow: 0 0 4px 0 rgba(black, 0.25);
            }
        }

        @include media(md) {
            min-width: 120px;
            height: 44px;
            font-size: 1.6rem;
            letter-spacing: .32px;
            line-height: 1.5;
        }

        &--Active {
            color: white;
            background-color: $red;

            &:hover {
                box-shadow: none;
            }
        }

        #{$root}__FilterContainer--Full & {
            min-width: 107px;

            @include media(md) {
                min-width: 161px;
            }
        }
    }

    &__Count {
        color: $gray-20;
        font-weight: normal;

        #{$root}__FilterButton--Active & {
            color: $red-20;
        }
    }

    &__DescriptionsWrapper {
        margin-top: 14px;
    }

    &__DescriptionsButton {
        position: relative;
        padding: 0;
        margin: 0;
        color: $gray-70;
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: .16px;
        background: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
        cursor: pointer;

        @include media(md) {
            font-size: 1.8rem;
            line-height: 1.56;
            letter-spacing: .2px;
        }

        &::before {
            content: '';
            display: inline-block;
            position: relative;
            top: 3px;
            width: 18px;
            height: 18px;
            margin-right: 8px;
            background: url('#{$publicpath}img/question.svg') no-repeat center center;
            background-size: contain;
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 26px;
            right: 0;
            height: 1px;
            background: $gray-70;
        }
    }

    &__Descriptions {
        display: flex;
        flex-wrap: wrap;
        margin-top: 24px;
        margin-left: -40px;
        padding-bottom: 21px;

        &:global {
            animation: slideDown 250ms ease forwards;
        }

        @include media(sm) {
            flex-wrap: nowrap;
        }

        @include media(md) {
            padding-bottom: 0;
        }

        @include media(lg) {
            margin-left: -90px;
        }
    }

    &__DescriptionColumn {
        flex: 1 1 auto;
        width: calc(100% + 40px);
        margin-left: 40px;

        @include media(md) {
            min-width: 417px;
        }

        @include media(lg) {
            margin-left: 90px;
        }

        &--Large {
            @include media(sm) {
                width: calc(#{percentage(7/12)} + 40px);
            }

            @include media(lg) {
                width: calc(#{percentage(7/12)} + 90px);
            }
        }

        &--Small {
            @include media(sm) {
                width: calc(#{percentage(5/12)} + 40px);
            }

            @include media(lg) {
                width: calc(#{percentage(5/12)} + 90px);
            }
        }
    }

    &__Description {
        margin-bottom: 24px;
    }

    &__DescriptionTitle {
        padding-bottom: 8px;
        color: $gray-70;
        font-size: 1.6rem;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0.4px;
        border-bottom: 1px solid #dfdfdf;

        @include media(md) {
            font-size: 2rem;
        }

        &::before {
            content: '';
            display: inline-block;
            position: relative;
            top: 3px;
            width: 18px;
            height: 18px;
            margin-right: 8px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        #{$root}__Description--Category &::before {
            background-image: url('#{$publicpath}img/icon-category.svg');
        }

        #{$root}__Description--Time &::before {
            background-image: url('#{$publicpath}img/icon-clock.svg');
        }

        #{$root}__Description--Group &::before {
            width: 24px;
            background-image: url('#{$publicpath}img/group.svg');
        }
    }

    &__DescriptionText {
        margin-top: 18px;
        color: $gray-70;
        font-size: 1.4rem;
        line-height: 1.56;
        letter-spacing: 0.2px;

        @include media(md) {
            font-size: 1.8rem;
        }
    }

    &__DescriptionItem {
        position: relative;
        color: $gray-70;

        &--Large {
            margin-top: 18px;
            padding-left: 45px;
        }

        h4 {
            font-size: 1.4rem;
            font-weight: bold;
            line-height: 1.33;
            letter-spacing: 0.36px;

            @include media(md) {
                font-size: 1.8rem;
            }
        }
    }

    &__DescriptionIcon {
        position: absolute;
        top: -3px;
        left: 0;
        width: 29px;
        height: 29px;
        font-size: 16px;
        border-radius: 50%;
        background: rgba($gray-70, .1);

        &::before {
            content: '';
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 16px;
            height: 16px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: contain;
        }

        &--HasFontawesome::before {
            top: -1px;
            left: 1px;
        }

        #{$root}__DescriptionItem--Starta &:not(#{&}--HasFontawesome)::before {
            background-image: url('#{$publicpath}img/rocket.svg');
        }

        #{$root}__DescriptionItem--Samspela &:not(#{&}--HasFontawesome)::before {
            background-image: url('#{$publicpath}img/stars.svg');
        }

        #{$root}__DescriptionItem--Energi &:not(#{&}--HasFontawesome)::before {
            background-image: url('#{$publicpath}img/bolt.svg');
        }

        #{$root}__DescriptionItem--Avsluta &:not(#{&}--HasFontawesome)::before {
            background-image: url('#{$publicpath}img/flag.svg');
        }
    }

    &__DescriptionItemText {
        font-size: 1.4rem;
        line-height: 1.56;
        letter-spacing: 0.2px;

        @include media(md) {
            font-size: 1.8rem;
        }

        p {
            display: inline;
            margin: 0;
        }

        b,
        strong {
            font-weight: bold;
        }

        em,
        i {
            font-style: italic;
        }

        a {
            color: $link-color;
            text-decoration: none;
            position: relative;

            &:hover {
                text-underline-offset: 2px;
                text-decoration-thickness: 1px;
                text-decoration: underline;
            }
        }
    }

    &__DescriptionsClose {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0;
        margin: 0 0 0 40px;
        color: $gray-70;
        font-size: 1.4rem;
        line-height: 1.5;
        letter-spacing: .16px;
        background: none;
        border: none;
        border-radius: 0;
        box-shadow: none;
        cursor: pointer;

        @include media(md) {
            display: none;
        }

        &::before {
            content: '';
            display: inline-block;
            position: relative;
            top: 1px;
            width: 12px;
            height: 12px;
            margin-right: 8px;
            background: url('#{$publicpath}img/icon-arrow.svg') no-repeat center center;
            background-size: contain;
            transform: rotate(180deg);
        }

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 20px;
            right: 0;
            height: 1px;
            background: $gray-70;
        }
    }

    &__Viewport {
        @include u-wrap(article);

        margin-top: 16px;
        margin-bottom: 0;

        @include media(sm) {
            margin-top: 24px;
            margin-bottom: 24px;
        }

        @include media(md) {
            margin-top: 48px;
            margin-bottom: 48px;
        }
    }

    &__ButtonWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 24px;

        @include media(md) {
            margin-bottom: 48px;
        }
    }

    &__ExtendButton {
        position: relative;
        display: inline-block;
        margin: 0 auto;
        font-size: 2rem;
        font-weight: bold;
        font-family: $base-font;
        line-height: 1.2;
        letter-spacing: 0.4px;
        color: $text;
        padding: 20px;
        border: none;
        border-radius: 0;
        background: none;
        box-shadow: none;
        cursor: pointer;

        &::after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 21px;
            height: 15px;
            margin: auto;
            background: transparent url('#{$publicpath}img/icon-arrow-down.svg')
                no-repeat;
            background-position: center center;
            transition: transform .2s;
        }

        &:hover {
            &::after {
                transform: translateY(2px);
            }
        }
    }
}
