@import 'Styles/includes';

/**
*
* CardAjaxLoader
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardAjaxLoader {
    border: 1px solid $gray-15;
    border-radius: 6px;

    &:global {
        animation: fadein 0.2s;
    }

    &__TimelineItem {
        background: white;
        padding: 62px 27px;
        margin: 0 auto;
        border-radius: 6px;
    }

    &__AnimatedBackground {
        animation-name: placeHolderShimmer;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        background: #f6f7f8;
        background: linear-gradient(
            to right,
            #eeeeee 8%,
            #dddddd 18%,
            #eeeeee 33%
        );
        background-size: 1000px 104px;
        height: 60px;
        position: relative;
    }

    &__BackgroundMasker {
        background: white;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 12px;

        &--ThirdEnd,
        &--FirstEnd,
        &--SecondEnd {
            width: auto;
            left: 70%;
            right: 0;
            top: 0;
            height: 12px;
        }

        &--SecondLine {
            top: 12px;
        }

        &--SecondEnd {
            left: 80%;
            top: 24px;
        }

        &--ThirdLine {
            top: 36px;
        }

        &--ThirdEnd {
            left: 60%;
            top: 48px;
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -468px 0;
    }
    100% {
        background-position: 468px 0;
    }
}
