@import 'Styles/includes';

/**
*
* ArrangementSearch
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ArrangementSearch {
    background: white;
    position: relative;

    @include media(md) {
        max-width: 756px;
        margin: 0 auto;
    }

    &__Tag {
        font-size: 1.6rem;
        font-weight: 700;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 5px;
        line-height: 1.5;
        margin-bottom: 8px;
        letter-spacing: 0.4px;

        @include media(lg) {
            font-size: 1.8rem;
            margin-bottom: 8px;
        }
    }

    &__Title {
        margin-bottom: 24px;
        font-weight: bold;
        line-height: 1.33;
        font-size: 2.4rem;

        @include media(lg) {
            font-size: 3.6rem;
        }
    }

    &__Header {
        padding-bottom: 16px;
        padding-top: 42px;
        text-align: center;

        @include media(md) {
            max-width: 75%;
            padding-top: 62px;
            padding-bottom: 32px;
            margin: 0 auto;
        }
    }

    &__FieldsWrapper {
        display: flex;
        flex-wrap: wrap;

        @include media(lg) {
            margin-left: 10px;
            width: 100%;
        }
    }

    &__AjaxLoaderContainer {
        position: absolute;
        bottom: 5px;
        left: 0;
        right: 0;
    }

    &__Field {
        outline: none;
        background: white;
        width: 100%;
        border: 1px solid $gray-40;
        padding: 18px 20px 20px 20px;
        color: #0c0c0c;
        font-size: 16px;
        line-height: 19px;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: right 30px center;
        background-size: 13px;

        &--Wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            margin-bottom: 15px;
        }

        @include media(md) {
            width: calc(50% - 10px);
            margin-right: 10px;
            max-height: 56px;
        }

        &--Search {
            border: none;
            width: calc(100% - 70px);
            padding: 19px 0 19px 20px;
            -webkit-appearance: none;
            background: transparent;
            height: 44px;
            line-height: 44px;

            @include media(md) {
                height: 56px;
                line-height: 54px;
            }

            &::placeholder {
                color: $black !important;
            }

            &::-webkit-search-cancel-button {
                -webkit-appearance: none;
                position: relative;
                background-image: url('#{$publicpath}img/close.svg');
                background-repeat: no-repeat;
                background-size: 12px 12px;
                background-position: center;
                height: 44px;
                width: 44px;
                right: 0;
            }
            &::-webkit-search-cancel-button:hover {
                cursor: pointer;
            }
        }

        &--City {
            padding: 0;
        }
    }

    &__Select {
        width: 100%;

        @include media(md) {
            width: 50%;
        }
    }

    &__Submit {
        border: none;
        padding: 0;
        background-image: url('#{$publicpath}img/icon-search.svg');
        width: 70px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px;
        border-radius: 0px 3px 3px 0px;
        cursor: pointer;
        background-color: transparent;

        &--Active {
            background-image: url('#{$publicpath}img/icon-search-white.svg');
            background-color: $button-bg;

            &:hover {
                background-color: $button-bg-hover;

                @media (hover: none) {
                    background-color: $button-bg;
                }
            }
        }
    }

    &__FiltersContainer {
        width: 100%;

        @include media(lg) {
            visibility: hidden;
            max-height: 50px;
        }
    }

    &__Checkbox {
        position: relative;
        margin-top: 8px;
        padding: 4px 0;
        cursor: pointer;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 0;
            height: 0;
            appearance: none;
        }

        label {
            padding-left: 32px;

            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 22px;
                width: 22px;
                background-color: #e1e1e1;
                border-radius: 4px;
                transition: background-color .3s ease-in-out;
            }

            &::after {
                content: '';
                position: absolute;
                width: 17px;
                height: 17px;
                top: 2px;
                left: 2px;
                background-size: cover;
                background-repeat: no-repeat;
                background-image: url('#{$publicpath}img/icon-checkmark.svg');
                opacity: 0;
                transition: opacity .3s ease-in-out;
            }
        }

        input:checked + label {
            &::before {
                background-color: $checkbox-bg;
            }

            &::after {
                opacity: 1;
            }
        }
    }
}
