@import 'Styles/includes';

.Root {
    $root: &;

    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    flex: 1;

    @include media(md) {
        padding: 56px 32px;
        border: 1px solid $gray-10;
        flex-direction: row;
    }

    &__ImageContainer {
        width: 100px;
        height: 100px;
        background-color: $gray-10;
        overflow: hidden;
        flex-shrink: 0;
        border-radius: 50%;

        @include media(md) {
            width: 300px;
            height: 300px;
            margin-right: 64px;
        }
    }

    &__Image {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        flex-shrink: 0;
        width: 100%;
        height: 100%;

        &--Placeholder {
            background-image: url('#{$publicpath}img/icon-office.svg');
            background-size: 50%;
        }
    }

    &__Container {
        width: 100%;
        text-align: center;
        margin-top: 25px;
        padding: 0 40px;

        @include media(xs) {
            padding: 0;
        }

        @include media(md) {
            margin-top: 0;
            max-width: auto;
            padding: 0;
            text-align: left;
        }
    }

    &__Title {
        font-weight: bold;
        margin-bottom: 12px;

        @include media(md) {
            font-size: 2.0rem;
        }
    }

    &__Preamble {
        font-size: 1.4rem;
        line-height: 1.5;
        color: $text;
        margin-bottom: 24px;
    }

    &__Text {
        font-size: 1.4rem;
        color: $text;
        margin-bottom: 5px;
    }

    &__WorkTitle {
        font-size: 1.4rem;
        color: $text;
        margin-bottom: 16px;
    }

    &__Info {
        border-top: 1px solid $gray-10;
        margin-top: 20px;
        padding-top: 20px;

        @include media(md) {
            border-top: 0;
        }
    }

    &__LinkWrapper {
        margin-bottom: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.4rem;
        line-height: 1.25;

        @include media(md) {
            justify-content: flex-start;
        }

        &:before {
            content: '';
            display: inline-block;
            width: 16px;
            height: 16px;
            margin-right: 8px;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
        }

        &--Email {
            text-transform: lowercase;

            &:before {
                background-image: url('#{$publicpath}img/icon-email.svg');
            }
        }

        &--Phone {
            &:before {
                background-image: url('#{$publicpath}img/icon-old-phone.svg');
            }
        }

        &--Postal {
            &:before {
                background-image: url('#{$publicpath}img/icon-mail.svg');
            }
        }

        &--Office {
            &:before {
                background-image: url('#{$publicpath}img/icon-location-black.svg');
                height: 10px;
                padding-bottom: 8px;
            }
        }
    }

    &__Link {
        text-decoration: none;
        color: $red;
        width: auto;
        font-size: 1.4rem;
        //white-space: nowrap;
        word-break: normal;
        line-height: 1.25;
        display: inline-flex;
        align-items: baseline;
        position: relative;

        text-decoration: none;

        &:hover {
            text-underline-offset: 2px;
            text-decoration-thickness: 1px;
            text-decoration: underline;
        }
    }
}
