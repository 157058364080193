@import 'Styles/includes';

/**
*
* CardResourceList
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.CardResourceList {
    $root: &;

    &__Tag {
        font-size: 1.6rem;
        font-weight: 700;
        font-weight: normal;
        text-transform: uppercase;
        margin-bottom: 5px;
        line-height: 1.5;
        margin-bottom: 8px;
        letter-spacing: 0.4px;

        @include media(lg) {
            font-size: 1.8rem;
            margin-bottom: 8px;
        }
    }

    &__Title {
        font-weight: bold;
        line-height: 1.33;
        font-size: 2.4rem;

        @include media(lg) {
            font-size: 3.6rem;
        }
    }

    &__Filter {
        text-align: left;

        @include media(md) {
            margin-top: 24px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
        }
    }

    &__Header {
        padding-bottom: 16px;
        margin-bottom: 24px;
        text-align: center;
        border-bottom: 1px solid $gray-20;

        @include media(md) {
            max-width: 75%;
            padding-bottom: 32px;
            margin: 0 auto 48px auto;
        }
    }

    &__Search {
        position: relative;
        margin: 16px auto 0 auto;
        width: 100%;
        margin-top: 16px;

        @include media(md) {
            margin: 0;
            width: 50%;

            #{$root}--Filter & {
                width: 40%;
                margin-right: 8px;
            }
        }
    }

    &__Categories {
        width: 100%;
        margin: 8px auto 0 auto;

        @include media(md) {
            margin: 0;
            width: 50%;

            #{$root}--Filter & {
                width: 40%;
                margin-left: 8px;
            }
        }
    }

    &__Clear {
        position: absolute;
        top: 1px;
        right: 50px;
        padding: 0;
        border: none;
        outline: none;
        padding: 0;
        background-image: url('#{$publicpath}img/icon-close--gray.svg');
        width: 36px;
        height: calc(100% - 2px);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 12px;
        cursor: pointer;
        background-color: white;
    }

    &__SearchField {
        margin: 0;
        outline: none;
        background: white;
        width: 100%;
        border: 1px solid $gray-40;
        padding: 18px 20px 20px 20px;
        color: #0c0c0c;
        font-size: 16px;
        line-height: 19px;
        border-radius: 4px;
        background-repeat: no-repeat;
        background-position: right 30px center;
        background-size: 13px;
        max-height: 52px;

        &:focus {
            border: 1px solid $black;
        }

        &--Wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;
            margin-bottom: 15px;
        }

        @include media(lg) {
            margin-right: 10px;
        }
    }

    &__LoaderWrapper {
        width: 35px;
        height: 35px;
        margin: 0 auto;
    }

    &__Submit {
        position: absolute;
        top: 0;
        right: 0;
        padding: 0;
        border: none;
        outline: none;
        padding: 0;
        background-image: url('#{$publicpath}img/icon-search.svg');
        width: 50px;
        height: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 15px;
        border-radius: 0px 6px 6px 0px;
        cursor: pointer;
        background-color: transparent;

        &--Active {
            background-image: url('#{$publicpath}img/icon-search-white.svg');
            background-color: $red;

            &:hover {
                background-color: $red-60;
                @media (hover: none) {
                    background-color: $red;
                }
            }

            &:active {
                background-color: $red-30;
            }
        }
    }

    &__SearchFieldWrapper {
        position: relative;
    }

    &__Resources {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        @include media(md) {
            margin-left: -24px;
            width: calc(100% + 24px);
        }
    }

    &__Resource {
        width: 100%;
        margin-bottom: 15px;
        background: white;
        box-shadow: 0 0 4px 0 rgba(black, 0.15);
        border-radius: 4px;
        transition: box-shadow 0.15s ease;

        @include media(md) {
            width: calc(100% / 2 - 24px);
            margin-bottom: 24px;
            margin-left: 24px;
        }

        @include media(lg) {
            width: calc(100% / 3 - 24px);
        }

        &:hover {
            box-shadow: 0 0 6px 0 rgba(black, 0.3);

            @media (hover: none) {
                box-shadow: 0 0 4px 0 rgba(black, 0.15);
            }
        }
    }

    &__NoHits {
        text-align: center;
        margin-bottom: 44px;
    }

    &__ButtonWrapper {
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
    }

    &__GoToPage {
        display: inline-block;
        margin: 0 auto;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
        letter-spacing: 0.4px;
        color: $text;
        text-decoration-line: none;
        padding-right: 22px;
        background: transparent url('#{$publicpath}img/icon-arrow-thin.svg')
            no-repeat;
        background-position: right center;
        background-size: 14px;
    }
}
