@import 'Styles/includes';

/**
*
* ArrangementEntry
*
* Styleguide/grouping order for css attributes:
* - Content, position, display, float, clear, overflow, zoom
* - Width, height, margin, padding
* - Table, column, list
* - Color, font, text, border, background, box-shadow, opacity
* - Cursor, z-index, transform, transition, animation
*/

.ArrangementEntry {
    $root: &;

    height: 100%;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(black, 0.15);
    background-color: white;
    overflow: hidden;

    &__Container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        #{$root}--NoImage & {
            justify-content: flex-end;

            @include media(md) {
                display: flex;
                align-items: flex-end;
            }
        }
    }

    &__Distance {
        width: fit-content;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1.2rem;
        letter-spacing: 0.9px;
        display: inline-flex;
        align-items: center;
        color: #961313;
        padding: 4px 8px;
        border-radius: 4px;
        background: rgba(175, 20, 50, 0.23);
    }

    &__Image {
        position: relative;
        width: 100%;
        height: 150px;
        overflow: hidden;
    }

    &__Title {
        color: $text;
        word-break: break-word;
        text-align: center;
        font-size: 2.0rem;
        font-weight: 600;
        margin-bottom: 24px;
        line-height: 1.33;
    }

    &__Categories {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-bottom: 32px;
    }

    &__Category {
        display: inline-block;
        font-size: 1.1rem;
        margin-bottom: 6px;
        text-transform: uppercase;
        letter-spacing: 0.8px;
        color: $black;
    }

    &__Content {
        width: 100%;
        padding: 16px 16px 16px 16px;
        display: flex;
        flex-direction: column;
        overflow: hidden;

        #{$root}--NoImage & {
            padding: 24px 16px 16px 16px;
        }
    }

    &__MetaList {
        display: flex;
        margin-bottom: 16px;
        width: 100%;
        justify-content: center;
        flex-direction: row;

        &--Vertical {
            flex-direction: column;
            align-items: center;
        }
    }

    &__MetaItem {
        color: $black;
        font-size: 1.1rem;
        margin-bottom: 6px;
        position: relative;
        text-transform: uppercase;
        flex-shrink: 0;
        letter-spacing: 0.8px;
        margin-left: 36px;

        @include media(md) {
            margin-bottom: 0;
        }

        &:first-of-type {
            margin-left: 0;
        }

        &::before {
            content: '';
            background-position: left center;
            background-repeat: no-repeat;
            background-size: contain;
            width: 13px;
            height: 11px;
            position: absolute;
            top: 0;
            left: 0px;
            padding: inherit;

            @include media(md) {
                left: -16px;
            }
        }
        
        &--Hybrid {
            margin-bottom: 8px;
        }

        &--Location {
            padding-left: 20px;

            @include media(md) {
                padding-left: 0;
            }

            &::before {
                background-image: url('#{$publicpath}img/icon-location-black.svg');
                width: 12px;
                height: 12px;
                top: -2px;
                left: 0px;

                @include media(md) {
                    left: -16px;
                }
            }
        }

        &--Price {
            padding-left: 8px;

            &::before {
                width: 17px;
                height: 13px;
                left: -20px;
                top: -2px;
                background-image: url('#{$publicpath}img/icon-card.svg');
            }
        }

        &--Date {
            padding-left: 8px;

            &::before {
                width: 17px;
                height: 17px;
                left: -20px;
                top: -4px;
                background-image: url('#{$publicpath}img/icon-clock.svg');
            }
        }
    }
}
